// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-faqs-page-js": () => import("/opt/build/repo/src/templates/faqs-page.js" /* webpackChunkName: "component---src-templates-faqs-page-js" */),
  "component---src-templates-menu-group-js": () => import("/opt/build/repo/src/templates/menu-group.js" /* webpackChunkName: "component---src-templates-menu-group-js" */),
  "component---src-templates-press-page-js": () => import("/opt/build/repo/src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-team-member-js": () => import("/opt/build/repo/src/templates/team-member.js" /* webpackChunkName: "component---src-templates-team-member-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

